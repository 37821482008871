
import { Options, Vue } from 'vue-class-component';
import Controls from './components/Controls.vue';
import './assets/styles/tailwind.css';

@Options({
  components: {
    Controls,
  },
})
export default class App extends Vue {}
