
import { Options, Vue } from 'vue-class-component';

@Options({
})
export default class Controls extends Vue {
  loading = false;

  code = '';

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramCode = urlParams.get('code');
    if (paramCode !== null) {
      this.code = paramCode;
    }
  }

  async openClick() {
    this.loading = true;
    const response = await fetch(`/open/${this.code}`, {
      method: 'POST',
    });

    this.loading = false;
    console.log(response);

    if (response.status !== 200) {
      alert("Failed to open")
    }
  }

}
