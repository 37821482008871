<template>
  <div>
    <div class="flex items-center justify-center mt-8">
      <div class="px-3 mb-6 md:mb-0">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
          Gate Code
        </label>
        <input v-model="code" class="text-center appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="tel" placeholder="000000">
      </div>

    </div>

    <button class="bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded" v-on:click="openClick">
      <svg v-if="loading" class="inline animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span class="inline">
      Open Gate
      </span>
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
})
export default class Controls extends Vue {
  loading = false;

  code = '';

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramCode = urlParams.get('code');
    if (paramCode !== null) {
      this.code = paramCode;
    }
  }

  async openClick() {
    this.loading = true;
    const response = await fetch(`/open/${this.code}`, {
      method: 'POST',
    });

    this.loading = false;
    console.log(response);

    if (response.status !== 200) {
      alert("Failed to open")
    }
  }

}
</script>

<style scoped>

</style>
